@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:100,300&display=swap');

$orange-colour: #ca9b5f;
$white-borders:1px solid #ffffff;
$orange-borders: 1px solid $orange-colour;

*{
    padding: 0;
    margin: 0;
}

#root {
    height: 100vh;
    width: 100vw;
}

html{
    width:100%;
    height: 100%;
}
body {
    background-image: url(/assets/starsPattern.svg);
    background-size: 20%;
    background-color: #20203c;
    height: 100%;
    font-family: 'Josefin Sans', sans-serif;
}
%borders{
    border: $white-borders;
}

%outside-margins{
    position: absolute;
    width: 1px;
    height: 35em;
    top: 40px; 
}
%diagonals{
    width: 1px;
    height: 30em;
    top:-15px;
}
.container {
    position: relative;
    display: block;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.main-margins {
    position: absolute;
    width: 70%;
    height: 40em;
    bottom:0;
    top:0;
    left:0;
    right:0;
    margin: auto;
    @extend %borders;
}
.diamond-shape {
    @extend %borders;
    
    position: relative;
    width: 70%;
    transform: rotate(45deg);
    left: 0;
    top: -130px;
    right: 0;
    bottom: 0;
    margin: auto; 

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    } 
}
.diamond-shape-gold {
    border:$orange-borders;
    
    position: relative;
    width: 95%;
    transform: rotate(45deg);
    left: 0;
    top: -590px;
    right: 0;
    bottom: 0;
    margin: auto; 

    &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
    } 
}
.outside-margin-left{
    @extend %outside-margins;
    border-left: $orange-borders;   
    left: -20px;
}
.outside-margin-right{
    @extend %outside-margins;
    border-right: $orange-borders;
    right:-20px;
}
.inside-margin-left{
    @extend %outside-margins;
    border-right: $orange-colour;
    left: -30px;
}
.bar-top, .bar-bottom{ 
        content: "";
        position: absolute;
        width: 65%;
        height: 1em;
        display: block;
        top:calc(-1em - 2px);
        left:0;
        right: 0;
        margin: auto;
        border:$orange-borders; 

        &:before, &:after {
            content: "";
            position: absolute;
            width: 45%;
            height: 1em;
            display: block;
            top: calc(-1em - 2px);
            left:0;
            right: 0;
            margin: auto;
            border:$white-borders;
            border-bottom-color:$orange-colour;
        }
    
        &:after{
            top:auto;
            bottom: calc(-1em - 2px);
            border-top-color:$orange-colour;
            border-bottom-color:white;
        }
    }
.bar-bottom{
    top:auto;
    bottom: calc(-1em - 2px);
}

.horoscope-of-day{
    position: relative;
    color:white;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 95;
    line-height: 1.9rem;
    letter-spacing: 1px;

    &::first-letter {
        text-transform: uppercase;
    }

    blockquote {
        &:after {
            content: '.';
            display: inline;
        }
    }

    &:before{
        position: absolute;
        content: '\201C';
        top: 0.25em;
        left: -0.25em;
        font-size: 17em;
        font-family: sans-serif;
        color: #ca9b5f;
        opacity: 0.6;
        z-index: -1;
    }
}

.star-sign-symbol {   
    width: 15%;
    margin: 7% auto 0 auto;
    fill:#ffffff;

    .highlighting {
        fill:$orange-colour;
    }
}

.star-david{
    $size: 4em;

    fill:#ffffff;
    width: $size;
    height: $size;
    color: $orange-colour;
    position: absolute;

    &.tl {
        top: $size / -2;
        left: $size / -2;
    }

    &.tr {
        top: $size / -2;
        right: $size / -2;
    }

    &.bl {
        bottom: $size / -2;
        left: $size / -2;
    }

    &.br {
        bottom: $size / -2;
        right: $size / -2;
    }

    .highlighting {
        fill:$orange-colour;
    }
}

.space-ray {
    $width: 100vw;
    $height: 14em;

    border-top: $white-borders;
    border-bottom: $orange-borders;
    height: $height;
    width: $width;
    position: absolute;
    box-sizing: border-box;

    &.tl {
        left: $width / -2;
        top: $height / -2;
        transform: rotate(-45deg);
    }

    &.tr {
        right: $width / -2;
        top: $height / -2;
        transform: rotate(45deg);
    }

    &.bl {
        left: $width / -2;
        bottom: $height / -2;
        transform: rotate(-135deg);
    }

    &.br {
        right: $width / -2;
        bottom: $height / -2;
        transform: rotate(135deg);
    }
}

.star-sign-name {
    width: 100%;
    padding: 3em 0;
    display: flex;
    justify-content: center;

    & > div {
        display: flex;
        align-items: center;
    }
}

.star-sign{
    display: inline-block;
    // width: 100%;
    color: $orange-colour;
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 300;
    user-select: none;
    padding: 0 1em;
}


.galaxy {
    @keyframes spin {
        0% {
            transform: rotate(0deg); 
        }

        100% { 
            transform: rotate(360deg); 
        }
    }

    & > * {
        transform-origin: 256px 256px;
    }

    $base-speed: 10s;

    .sun {
        fill:$orange-colour;
        animation: spin $base-speed linear infinite;
    }

    .inner-arc {
        animation: spin ($base-speed * 3) linear infinite reverse;
    }

    .outter-arc {
        animation: spin ($base-speed * 4) linear infinite;
    }

    .stars {
        fill: $orange-colour;
        animation: spin ($base-speed * 4) linear infinite;
    }
}

.arrow {
    display: inline-block;
    cursor: pointer;
    transform: scaleX(-1);
    fill: none;
    stroke: white;
    transform-origin: center center;
    stroke-width: 5;
    width: 4rem;
    max-height: 5.5rem;

    &:hover {
        stroke: $orange-colour;
    }

    &.right {
        transform: scaleX(1);
    }
}

.loading-page{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.box{
    width: 50%;
    height: 60%;
}

.loading-title{
    color: $orange-colour;
    text-transform: uppercase;
    font-size: 3.5em;    
    text-align: center;
    user-select: none;
}
.loading-image{
    width: 30%;
    margin: 7% auto 0 auto;
    fill:#ffffff;
}
.loading-text, .loading-about {
    color: white;
    font-size: 1.2em;
    text-align: center;
    user-select: none;
    padding: .5em 0 1.5em 0;
}

.loading-about {
    font-size: 1.5em;
    padding-top: 1.5em;
    border-top: solid 1px #ffffff77;
}


                    ////////// Media queries ///////////
 

@media only screen and (max-width:414px) and (max-height: 812px) and (min-height:668px){

    .star-sign-symbol {   
        width: 25%;
        margin: 40% auto 0 auto;    
    }
    .star-sign-name {
        padding: 1.6em 0;
    }
    .star-sign{
        font-size: 1.4rem; 
        padding: 0 1em;
    }  
    .arrow { 
        width: 2rem;
        max-height: 5.5rem;
    }
    .horoscope-of-day{
        width: 85%;
        font-size: 1rem;
        line-height: 1.6rem;

        &:before{
            top: 0.25em;
            left: -0.15em;
            font-size: 5.7em;          
        }
    }

             //////// LOAD COMPONENTS //////
    
    .loading-title{
        font-size:2.2em;
    }
    .loading-text{
        font-size:1em;
    }
    .loading-about{
        font-size: 1em;
    }
}

@media only screen and (max-width:1024px) and (min-width: 768px) and (max-height:1370px) and (min-height: 1000px){
  
    .star-sign-symbol {   
        width: 20%;
        margin: 10% auto 0 auto;    
    }
    .star-sign-name {
        padding: 1.6em 0;
    }
    .star-sign{
        font-size: 2.9rem; 
        padding: 0 1em;
    }  
    .arrow { 
        width: 4rem;
        max-height: 5.5rem;
    }
    .horoscope-of-day{
        width: 75%;
        font-size: 1.6rem;
        line-height: 1.9rem;

        &:before{
            top: 0.25em;
            left: -0.15em;
            font-size: 5.7em;          
        }
    }
}
@media only screen and (max-width: 414px) and (max-height: 736px){
    .container{
        overflow: hidden;
    }
    .star-sign-symbol {   
        width: 30%;
        margin: 40% auto 0 auto;    
    }
    .star-sign-name {
        padding: 1.0em 0;
    }
    .star-sign{
        font-size: 1rem; 
        padding: 0 1em;
    }  
    .arrow { 
        width: 1.7rem;
        max-height: 5.5rem;
    }
    .horoscope-of-day{
        width: 80%;
        font-size: 1rem;
        line-height: 1.5rem;

        &:before{
            top: 0.25em;
            left: -0.15em;
            font-size: 5.7em;          
        }
    }
}

    @media only screen and (max-width:790px)  and (max-height: 769px){
    
    .star-sign-symbol {   
        width: 20%;
        margin: 20% auto 0 auto;    
    }
    .star-sign-name {
        padding: 2.0em 0;
    }
    .star-sign{
        font-size: 1.7rem; 
        padding: 0 1em;
    }  
    .arrow { 
        width: 2.5rem;
        max-height: 5.5rem;
    }
    .horoscope-of-day{
        width: 80%;
        font-size: 1.4rem;
        line-height: 2rem;

        &:before{
            top: 0.25em;
            left: -0.15em;
            font-size: 5.7em;          
        }
    }
}
@media only screen and (max-width: 360px) and (max-height: 640px){
    .container{
        overflow: hidden;
    }
    .star-sign-symbol {   
        width: 18%;
        margin: 40% auto 0 auto;    
    }
    .star-sign-name {
        padding: 1.0em 0;
    }
    .star-sign{
        font-size: 1.2rem; 
        padding: 0 1em;
    }  
    .arrow { 
        width: 2.2rem;
        max-height: 5.5rem;
    }
    .horoscope-of-day{
        width: 80%;
        font-size: 1rem;
        line-height: 1.6rem;

        &:before{
            top: 0.25em;
            left: -0.15em;
            font-size: 5.7em;          
        }
    }
}
            ////// LANDSCAPE MODE /////////

@media only screen and (max-width: 812px) and (max-height: 375px){
    
    .container{
        overflow: hidden;
    }
    .star-sign-symbol {   
        width: 13%;
        margin: 30% auto 0 auto;    
    }
    .star-sign-name {
        padding: 1.0em 0;
    }
    .star-sign{
        font-size: 1.9rem; 
        padding: 0 1em;
    }  
    .arrow { 
        width: 2.8rem;
        max-height: 5.5rem;
    }
    .horoscope-of-day{
        width: 80%;
        font-size: 1.2rem;
        line-height: 1.8rem;

        &:before{
            top: 0.25em;
            left: -0.15em;
            font-size: 5.7em;          
        }
    }
}
@media only screen and (max-width: 640px) and (max-height: 360px){
    .container{
        overflow: hidden;
    }
    .star-sign-symbol {   
        width: 12%;
        margin: 40% auto 0 auto;    
    }
    .star-sign-name {
        padding: 1.0em 0;
    }
    .star-sign{
        font-size: 1.2rem; 
        padding: 0 1em;
    }  
    .arrow { 
        width: 2.5rem;
        max-height: 5.5rem;
    }
    .horoscope-of-day{
        width: 80%;
        font-size: 1.1rem;
        line-height: 1.6rem;

        &:before{
            top: 0.25em;
            left: -0.15em;
            font-size: 5.7em;          
        }
    }
}
@media only screen and (max-width: 667px) and (max-height: 375px){
    .container{
        overflow: hidden;
    }
    .star-sign-symbol {   
        width: 12%;
        margin: 40% auto 0 auto;    
    }
    .star-sign-name {
        padding: 1.0em 0;
    }
    .star-sign{
        font-size: 1.2rem; 
        padding: 0 1em;
    }  
    .arrow { 
        width: 2.5rem;
        max-height: 5.5rem;
    }
    .horoscope-of-day{
        width: 80%;
        font-size: 1.1rem;
        line-height: 1.6rem;

        &:before{
            top: 0.25em;
            left: -0.15em;
            font-size: 5.7em;          
        }
    }
}

@media only screen and (max-width: 736px) and(max-height:414px){
    .container{
        overflow: hidden;
    }
    .star-sign-symbol {   
        width: 15%;
        margin: 35% auto 0 auto;    
    }
    .star-sign-name {
        padding: 1.0em 0;
    }
    .star-sign{
        font-size: 1.2rem; 
        padding: 0 1em;
    }  
    .arrow { 
        width: 1.7rem;
        max-height: 5.5rem;
    }
    .horoscope-of-day{
        width: 80%;
        font-size: 1.1rem;
        line-height: 1.6rem;

        &:before{
            top: 0.25em;
            left: -0.15em;
            font-size: 5.7em;          
        }
    }
}
@media only screen and (max-width: 375px) and (max-height: 667px){
    .container{
        overflow: hidden;
    }
    .star-sign-symbol {   
        width: 30%;
        margin: 40% auto 0 auto;    
    }
    .star-sign-name {
        padding: 1.0em 0;
    }
    .star-sign{
        font-size: 1.5rem; 
        padding: 0 1em;
    }  
    .arrow { 
        width: 1.8rem;
        max-height: 5.5rem;
    }
    .horoscope-of-day{
        width: 80%;
        font-size: 1.2rem;
        line-height: 1.5rem;

        &:before{
            top: 0.25em;
            left: -0.15em;
            font-size: 5.7em;          
        }
    }
       //////// LOAD COMPONENTS //////

    .loading-title{
        font-size:1.7em;
    }
    .loading-text{
        font-size:1em;
    }
    .loading-about{
        font-size: 1em;
    }
}
